<template>
  <div v-if="isLoading" class="flex justify-center gap-1">
    <div class="spinner dark"></div>
    <div>Loading</div>
  </div>
  <div v-else>
    <div class="flex justify-between pb-2 items-center">
      <h3 class="text-xl font-semibold ">
        Users
      </h3>
      <router-link
        :to="`${route.path}/add`"
        class="hover-trigger flex align-middle gap-1 text-blue-600 hover:text-blue-700"
      >
        <span class="hover-target pt-1 font-medium">Add</span>
        <svg
          class="h-8 w-8"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </router-link>
    </div>
    <div class="shadow overflow-hidden border border-gray-200">
      <table class="w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th
              v-show="false"
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              id
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              name
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              email
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              role
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              status
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr
            v-for="user in documents"
            :key="user.id"
            class="hover:bg-gray-50"
            @click="goTo(`${route.path}/${user.id}`)"
          >
            <td
              v-show="false"
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
            >
              {{ user.id }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ user.user_name }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ user.user_email }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ user.user_role }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ user.user_status }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import getCollection from "@/composables/getCollection";

export default {
  props: ["id", "user"],
  setup(props) {
    const isLoading = ref(true);
    const route = useRoute();
    const router = useRouter();
    const business_id = props.user.claims.business_id ?? props.id;

    const { error, documents } = getCollection(
      `businesses/${business_id}/users`
    );

    const goTo = (url) => {
      router.push(url);
    };

    watchEffect(() => {
      if (documents.value) {
        isLoading.value = false;
      }
    });

    return { isLoading, route, error, documents, goTo };
  },
};
</script>
