import { ref } from 'vue'
import { storage } from '@/firebaseConfig'

export default function useStorage(business_id, directory) {
  const error = ref(null)
  const url = ref(null)
  const filePath = ref(null)

  const uploadFile = async (file) => {
    filePath.value = `${business_id}/${directory}/${file.name}`
    const storageRef = storage.ref(filePath.value)

    try {
      const res = await storageRef.put(file.value)
      url.value = await res.ref.getDownloadURL()
      return url.value;
    }
    catch (err) {
      console.error(err.message)
      error.value = err
    }
  }

  const getDownloadURL = async (path) => {
    if (path) {
      const ref = storage.ref(path);
      return await ref.getDownloadURL();
    }
     
  
 
  }

  const deleteFile = async (path) => {
    const storageRef = storage.ref(path)

    try {
      await storageRef.delete()
    } catch (err) {
      console.error(err.message)
      error.value = err
    }
  }

  return { error, url, filePath, uploadFile, deleteFile, getDownloadURL }
}
