<template>
  <div>
    <div class="block">
      <nav class="flex space-x-4" aria-label="Tabs">
        <router-link
          :to="`${parent}/settings`"
          class="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded"
          :class="{
            'bg-blue-100 text-blue-700': tab == 'settings',
          }"
        >
          Settings
        </router-link>
        <router-link
          :to="`${parent}/users`"
          class="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded"
          :class="{
            'bg-blue-100 text-blue-700': tab == 'users',
          }"
        >
          Users
        </router-link>
        <router-link
          :to="`${parent}/services`"
          class="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded"
          aria-current="page"
          :class="{
            'bg-blue-100 text-blue-700': tab == 'services',
          }"
        >
          Services
        </router-link>
        <router-link
          :to="`${parent}/crews`"
          class="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded"
          aria-current="page"
          :class="{
            'bg-blue-100 text-blue-700': tab == 'crews',
          }"
        >
          Crews
        </router-link>
        <router-link
          :to="`${parent}/customertypes`"
          class="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded"
          aria-current="page"
          :class="{
            'bg-blue-100 text-blue-700': tab == 'customertypes',
          }"
        >
          Customer Types
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";

export default {
  props: ["tab"],
  setup() {
    const route = useRoute();
    const parent = route.path.substring(0, route.path.lastIndexOf("/"));

    return { parent };
  },
};
</script>
