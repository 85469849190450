<template>
  <div class="business p-8">
    <div class="mx-auto max-w-5xl space-y-10">
      <template v-if="error">
        <h1 class="text-3xl font-semibold">Business</h1>
        <div class="text-center text-red-600 font-semibold">
          Error loading business: {{ error }}
        </div>
      </template>
      <template v-if="!error">
        <h1 class="text-3xl font-semibold ">
          {{ business.business_name }}
        </h1>
        <BusinessTabs :tab="tab" />
        <BusinessList v-if="tab == 'settings'" :id="business_id" :user="user" />
        <ServiceList v-if="tab == 'services'" :id="business_id" :user="user" />
        <UserList v-if="tab == 'users'" :id="business_id" :user="user" />
        <CrewList v-if="tab == 'crews'" :id="business_id" :user="user" />
        <CustomerTypeList
          v-if="tab == 'customertypes'"
          :id="business_id"
          :user="user"
        />
      </template>
    </div>
  </div>
</template>

<script>
import BusinessList from "@/components/business/BusinessList";
import BusinessTabs from "@/components/business/BusinessTabs";
import ServiceList from "@/components/business/ServiceList";
import UserList from "@/components/business/UserList";
import CrewList from "@/components/business/CrewList";
import CustomerTypeList from "@/components/business/CustomerTypeList";
import getDocument from "@/composables/getDocument";

export default {
  components: {
    BusinessList,
    BusinessTabs,
    ServiceList,
    UserList,
    CustomerTypeList,
    CrewList,
  },
  props: ["id", "tab", "user"],
  setup(props) {
    const business_id = props.user.claims.business_id ?? props.id;

    const { error, document: business } = getDocument(
      "businesses",
      business_id
    );

    return {
      error,
      business,
      business_id,
    };
  },
};
</script>
