<template>
  <div v-if="isLoading" class="flex justify-center gap-1">
    <div class="spinner dark"></div>
    <div>Loading</div>
  </div>
  <div v-else>
    <div class="flex justify-between pb-2 items-center">
      <h3 class="text-xl font-semibold h-8">
        Business Info
      </h3>
      <router-link
        :to="`${route.path}/edit`"
        class="hover-trigger flex align-middle gap-1 text-blue-600 hover:text-blue-700"
      >
        <span class="hover-target pt-1 font-medium">Edit</span>
        <svg
          class="h-7 w-7"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
          />
        </svg>
      </router-link>
    </div>

    <div class="shadow overflow-hidden border border-gray-200">
      <table class="w-full divide-y divide-gray-200">
        <tbody>
          <tr class="hover:bg-gray-50 border-b">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gray-100 border-r"
            >
              <div class="uppercase">Logo</div>
              <div>(250px x 200px)</div>
            </td>
            <td class="px-6 py-4 text-sm text-gray-500 w-full">
              <img class="w-64" :src="business.business_logo" />
            </td>
          </tr>

          <tr class="hover:bg-gray-50 border-b">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r"
            >
              Name
            </td>
            <td class="px-6 py-4 text-sm text-gray-500 w-full">
              {{ business.business_name }}
            </td>
          </tr>

          <tr class="hover:bg-gray-50 border-b">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r"
            >
              Phone
            </td>
            <td class="px-6 py-4 text-sm text-gray-500 w-full">
              {{ business.business_phone }}
            </td>
          </tr>

          <tr class="hover:bg-gray-50 border-b">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r"
            >
              Address
            </td>
            <td class="px-6 py-4 text-sm text-gray-500 w-full">
              <div v-if="business.business_address1">
                {{ business.business_address1 }}
              </div>
              <div v-if="business.business_address2">
                {{ business.business_address2 }}
              </div>
              <div
                v-if="
                  business.business_city ||
                    business.business_state ||
                    business.business_zip
                "
              >
                {{ business.business_city }} {{ business.business_state }}
                {{ business.business_zip }}
              </div>
            </td>
          </tr>

          <tr
            v-show="user.claims?.user_role == 'superadmin'"
            class="hover:bg-gray-50 border-b"
          >
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r"
            >
              Billing
            </td>
            <td class="px-6 py-4 text-sm text-gray-500">
              {{ business.business_type }}
            </td>
          </tr>

          <tr
            v-show="user.claims?.user_role == 'superadmin'"
            class="hover:bg-gray-50 border-b"
          >
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r"
            >
              Status
            </td>
            <td class="px-6 py-4 text-sm text-gray-500">
              {{ business.business_status }}
            </td>
          </tr>

          <tr class="hover:bg-gray-50 border-b">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r"
            >
              Email From
            </td>
            <td class="px-6 py-4 text-sm text-gray-500">
              {{ business.settings_default_email_from }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h3 class="text-xl font-semibold h-8 mt-6">
      Quote settings
    </h3>
    <div class="shadow overflow-hidden border border-gray-200 mt-1">
      <table class="w-full divide-y divide-gray-200">
        <tbody>
          <tr class="hover:bg-gray-50 border-b">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r"
            >
              Quote Email Template
            </td>
            <td class="px-6 py-4 text-sm text-gray-500 whitespace-pre-wrap">
              {{ business.settings_default_email_content }}
            </td>
          </tr>

          <tr class="hover:bg-gray-50 border-b">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gray-100 border-r"
            >
              <div class="uppercase">Quote Header Extras</div>
              <div>(2-3 lines max)</div>
            </td>
            <td
              class="px-6 py-4 text-sm text-gray-500 w-full whitespace-pre-wrap"
            >
              {{ business.quote_header_extras }}
            </td>
          </tr>

          <tr class="hover:bg-gray-50 border-b">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r"
            >
              Days for Acceptance
            </td>
            <td
              class="px-6 py-4 text-sm text-gray-500 w-full whitespace-pre-wrap"
            >
              {{ business.quote_acceptance_days }}
            </td>
          </tr>

          <tr class="hover:bg-gray-50 border-b">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r"
            >
              Quote Footer 1
            </td>
            <td
              class="px-6 py-4 text-sm text-gray-500 w-full whitespace-pre-wrap"
            >
              {{ business.quote_footer_1 }}
            </td>
          </tr>

          <tr class="hover:bg-gray-50 border-b">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r"
            >
              Quote Footer 2
            </td>
            <td
              class="px-6 py-4 text-sm text-gray-500 w-full whitespace-pre-wrap"
            >
              {{ business.quote_footer_2 }}
            </td>
          </tr>

          <tr class="hover:bg-gray-50 border-b">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r"
            >
              Quote Footer 3
            </td>
            <td
              class="px-6 py-4 text-sm text-gray-500 w-full whitespace-pre-wrap"
            >
              {{ business.quote_footer_3 }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h3 class="text-xl font-semibold h-8 mt-6">
      Job settings
    </h3>
    <div class="shadow overflow-hidden border border-gray-200 mt-1">
      <table class="w-full divide-y divide-gray-200">
        <tbody>
          <tr class="hover:bg-gray-50 border-b">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gray-100 border-r"
            >
              <div class="uppercase">
                Job Completed Email Template
              </div>
            </td>
            <td
              class="px-6 py-4 text-sm text-gray-500 w-full whitespace-pre-wrap"
            >
              {{ business.job_email_template }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h3 class="text-xl font-semibold h-8 mt-6">
      Invoice settings
    </h3>
    <div class="shadow overflow-hidden border border-gray-200 mt-1">
      <table class="w-full divide-y divide-gray-200">
        <tbody>
          <tr class="hover:bg-gray-50 border-b">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gray-100 border-r"
            >
              <div class="uppercase">
                Invoice Footer
              </div>
            </td>
            <td
              class="px-6 py-4 text-sm text-gray-500 w-full whitespace-pre-wrap"
            >
              {{ business.invoice_footer }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h3 class="text-xl font-semibold h-8 mt-6">
      Mailgun settings
    </h3>
    <div class="shadow overflow-hidden border border-gray-200 mt-1">
      <table class="w-full divide-y divide-gray-200">
        <tbody>
          <tr class="hover:bg-gray-50 border-b">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gray-100 border-r"
            >
              <div class="uppercase">
                Mailgun API Key
              </div>
            </td>
            <td
              class="px-6 py-4 text-sm text-gray-500 w-full whitespace-pre-wrap"
            >
              {{
                business.mailgun_api_key && business.mailgun_api_key != ""
                  ? "••••••••••••••••••••••••••••••"
                  : ""
              }}
            </td>
          </tr>
          <tr class="hover:bg-gray-50 border-b">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 bg-gray-100 border-r"
            >
              <div class="uppercase">
                Mailgun Domain
              </div>
            </td>
            <td
              class="px-6 py-4 text-sm text-gray-500 w-full whitespace-pre-wrap"
            >
              {{ business.mailgun_domain }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import getDocument from "@/composables/getDocument";
import useStorage from "@/composables/useStorage";

export default {
  props: ["id", "user"],
  setup(props) {
    const isLoading = ref(true);
    const route = useRoute();
    let isImageFirstTime = false;

    const business_id = props.user.claims.business_id ?? props.id;
    const { getDownloadURL: getDownloadURL } = useStorage(
      business_id,
      "assets"
    );

    const { error, document: business } = getDocument(
      "businesses",
      business_id
    );

    watchEffect(async () => {
      if (business.value) {
        isLoading.value = false;
      }

      if (!isImageFirstTime && business.value && business.value.business_logo) {
        business.value.business_logo = await getDownloadURL(
          business.value.business_logo
        );
        isImageFirstTime = !isImageFirstTime;
      }
    });

    return {
      isLoading,
      route,
      error,
      business,
    };
  },
};
</script>
